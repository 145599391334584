<template>
  <div class="ParQ">
        <div v-if="steps == 0" class="tips">
          <h2>体能评测活动问卷（PAR-Q）</h2>
          <div>本问卷旨在了解您的健康状况，以增加关节评测的安全性，所有信息仅作留档使用，请根据您的实际情况填写问卷内容。</div>
          <el-button type="primary" @click="next()">开始填写</el-button>
        </div>
        <div v-if="steps == 1">
          <h2>体能评测活动问卷（PAR-Q）</h2>
          <van-form @submit="thirdStep">
            <p class="question">1.医生是否告诉过你患有心脏病并仅能参加医生推荐的活动?</p>
            <van-radio-group v-model="parQData.q1" direction="horizontal">
              <van-radio :name="1">是</van-radio>
              <van-radio :name="0">否</van-radio>
            </van-radio-group>

            <p class="question">2.当您进行体力活动时是否感觉胸痛?</p>
            <van-radio-group v-model="parQData.q2" direction="horizontal">
              <van-radio :name="1">是</van-radio>
              <van-radio :name="0">否</van-radio>
            </van-radio-group>

            <p class="question">3.自上个月来，您是否在没有体力活动的时候有胸痛?</p>
            <van-radio-group v-model="parQData.q3" direction="horizontal">
              <van-radio :name="1">是</van-radio>
              <van-radio :name="0">否</van-radio>
            </van-radio-group>

            <p class="question">4.你是否因为头晕跌倒或失去知觉?</p>
            <van-radio-group v-model="parQData.q4" direction="horizontal">
              <van-radio :name="1">是</van-radio>
              <van-radio :name="0">否</van-radio>
            </van-radio-group>

            <p class="question">5.近来医生是否因为你的血压或心脏问题给你开药?</p>
            <van-radio-group v-model="parQData.q5" direction="horizontal">
              <van-radio :name="1">是</van-radio>
              <van-radio :name="0">否</van-radio>
            </van-radio-group>

            <p class="question">6.你是否知道一些你不能进行体力活动的其它原因?</p>
            <van-radio-group v-model="parQData.q6" direction="horizontal">
              <van-radio :name="1">是</van-radio>
              <van-radio :name="0">否</van-radio>
            </van-radio-group>

            <van-button block type="info" native-type="submit">提交</van-button>
          </van-form>
        </div>
  </div>
</template>
<script>
import { addParQ } from "@/api/getData"
import { Toast } from "vant"

export default {
  name: "ParQ",
  data() {
    return {
      steps:0,
      qName:'',
      parQData:{
        userId: '',
        q1:"",
        q2:"",
        q3:"",
        q4:"",
        q5:"",
        q6:"",
      }
    }
  },
  methods: {
    //下一步
    next(){
      this.steps = 1
    },
    //提交表单
    thirdStep(){
      for (var key in this.parQData) {
        if (this.parQData[key] === "") {
          return Toast('请填写全部问卷')
        }
      }
      addParQ(this.parQData).then(res => {
          if(res.data.status == 1){
            this.$confirm('您属于运动中高风险人群，建议开始运动前到专业医生处就诊，或在专业医师指导下进行运动', '温馨提示', {
              confirmButtonText: '我知道了',
              type: 'warning',
              showCancelButton: false,
              confirmButtonClass: 'confirm-button',
              center: true
            }).then(() => {
              this.toQ();
            }).catch(()=>{})
          }else{
            this.toQ();
          }
      })
    },
    //跳转到对应的问卷
    toQ(){
      if(this.qName == 'KneeQ'){//膝
        this.$router.push({path: "/knee-q"})
      }else if(this.qName == 'HipQ'){//髋
        this.$router.push({path: "/hip-q"})
      }else if(this.qName == 'BackQ'){//腰背
        this.$router.push({path: "/back-q"})
      }else{
        this.$message({type: 'error', message: '未找到相应的问卷!'});
      }
    }
  },
  mounted() {
    this.parQData.userId = this.vUserId;
    this.qName = this.$route.query.qName;
  }
}
</script>

<style lang="scss" scoped>
.ParQ {
  padding: 0 .16rem;
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;

  h2 {
    font-size: .18rem;
    font-weight: 700;
    line-height: 0.32rem;
    padding-top: .48rem;
    opacity: 0.9;
  }

  .tips {
    h2 {
      line-height: .24rem;
      font-size: .14rem;
      padding-bottom: .18rem;
    }

    div {
      font-size: .14rem;
      line-height: .24rem;
      color: #3D3D3D;
      margin-bottom: 3.45rem;
    }

    .el-button {
      width: 100%;
    }
  }

  .van-form {
    font-size: 0.14rem;
    font-weight: 700;

    .question {
      line-height: 0.24rem;
      margin: 0.48rem 0 0.16rem;
      color: #3d3d3d;
    }

    .van-cell {
      background: none;
      padding: 0;
    }

    :deep(.van-field__control--custom) {
      display: block !important;
    }

    .van-cell::after {
      border: none;
    }

    :deep(.van-radio) {
      width: 0.61rem;
      height: 0.41rem;
      align-items: center;
      justify-content: center;
      border-radius: 0.04rem;

      .van-radio__icon {
        width: 0.16rem;
        height: 0.16rem;
      }

      .van-radio__label {
        margin-left: 0.1rem;
      }

      .van-radio__icon--checked .van-icon {
        background-color: #24bbd3 !important;
        border-color: #24bbd3 !important;
      }

      .van-radio__icon.van-radio__icon--checked + .van-radio__label {
        color: #24bbd3;
      }
    }

    .van-radio--horizontal {
      margin-right: 0;
    }

    .van-radio + .van-radio {
      margin-left: 0.26rem;
    }

    .van-button {
      height: 0.48rem;
      margin: 0.8rem 0 0.32rem;
      border-radius: 0.4rem;
    }
  }
}
</style>